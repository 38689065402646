import React from 'react'
import CaseStudiesSidebar from './CaseStudiesSidebar'
import details1 from '../../assets/images/projects/projects-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Innovation Centre IT Build</span>
                            <h3>Complete New Install of IT Infrastructure</h3>
                            <p>Brand new 11,000 Sq Ft Office in Dusseldorf Germany. Scope Requirements, Locate Local Suppliers, Install Servers, Install Munki MDM for Apple Device Management, Install Cisco Wi-Fi</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Location had no Mobile Data Coverage</li>
                                            <li>Power to Server Room was limited</li>
                                            <li>Space and Size of Server Room was Limited</li>
                                            <li>New Branch to be incorporated into to Corporate Network</li>
                                            <li>2 Week timeframe from build to delivery</li>
                                            <li>Deliver with WOW</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p>Once the scope was collected the process of locating local suppliers for IT equiptment began, it was very nice to install a build in a brand new premise and have a lot of say in the design</p>
                            <p>The planning was over 3 months, travelling back and forth between Germany and the UK, once all suppliers where lined up and services procured it was about delivering in a short time frame of 2 weeks.</p>
                            <h3>Results</h3>
                            <p>Everything went well and all IT was delivered on time and a big saving on the budget.  The best result was seeing the staff arrive with there MacBooks, opening the lids and working without a single touch to the configuration.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContent